// css / scss
import './scss/_themeconfig.scss';
import 'reset-css/sass/_reset.scss';
import './scss/style.scss';
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'


// javascript
import $ from 'jquery';
import slick from 'slick-carousel'
import ua from './js/ua';

if (_ua.Tablet) {
  $(() => { $('#viewport').attr('content', 'width=1200, user-scalable=yes') });
}

$('#nav-toggle').on('click', function(){
  $('#nav').fadeToggle()
})

if (_ua.Mobile) {
  $('.nav-link--toggle').on('click', function(){
    $(this).toggleClass('is-active').next().slideToggle()
    return false
  })
}

$('#slider-home').slick({
  fade: true,
  autoplay: true,
  infinite: true,
  arrows: false
});
